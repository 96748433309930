import $events from 'wee-events';
import { $chain } from 'core/chain';
import { $setVar } from 'wee-store';
import { $setRef } from 'core/dom';
import { noOutline } from './utilities';
import 'es6-promise/auto';
import '../styles/global.scss';

// Import all component scss files
require.context('../components', true, /\.scss$/);

$setRef();
$setVar();

$events.on({
    a: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    button: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    input: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
}, {
    delegate: 'body',
});

$chain({
    tabindex(index) {
        this.attr('tabindex', index);

        return this;
    },
});
