import $router from 'wee-routes';
import $ from 'wee-dom';
import $mediator from 'wee-mediator';
import './bootstrap';

/**
 * Dynamically load a component
 * @param {String} component
 */
const load = component => import(/* webpackChunkName: "[request]" */ `../components/${component}`)
    .then(m => m.default || m);

const navigation = () => load('navigation');
const mainNav = () => load('main-nav');
const bugherd = () => load('bugherd');
const doc = () => load('doc');
const search = () => load('search');
const form = () => load('form');
const filter = () => load('team');

const common = [navigation, mainNav, bugherd, search];

const paginated = 'p(\\d+)';

$router.map([
    {
        path: '/',
        handler: [mainNav, search],
    },
    {
        path: 'login',
        handler: [mainNav, search, form],
    },
    {
        path: 'registration',
        handler: [mainNav, search, form],
    },
    {
        path: 'password-reset',
        handler: [mainNav, search, form],
    },
    {
        path: 'password-confirmation',
        handler: [mainNav, search],
    },
    {
        path: 'registration-confirmation',
        handler: [mainNav, search],
    },
    {
        path: 'employee-directory',
        handler: [filter, mainNav, search],
    },
    {
        path: `employee-directory/${paginated}`,
        handler: [filter, mainNav, search],
    },
    {
        path: 'blog',
        handler: [doc, ...common],
    },
    {
        path: `blog/${paginated}`,
        handler: [doc, ...common],
    },
    {
        path: ':department',
        handler: common,
    },
    {
        path: ':department/:section',
        handler: common,
    },
    {
        path: ':department/:section/:subsection',
        handler: [doc, ...common],
    },
]).beforeEach((to, from, next) => {
    $mediator.emit('navigation.start');

    next();
}).afterEach(() => {
    $('table').addClass('table -striped');
    $mediator.emit('navigation.end');
}).onError((error) => {
    if (error.errorType === 'SameRouteError') {
        $mediator.emit('close.menu');
    }
}).run();
