import $ from 'wee-dom';
import $events from 'wee-events';

/**
 * Add/Remove outline styles
 * @param {element} element
 */
const noOutline = (element) => {
    const $el = $(element);

    if ($el.css('outline-style') === 'none') {
        const outlineClass = '-no-outline';

        $el.addClass(outlineClass);

        $events.on($el, 'blur', () => {
            $el.removeClass(outlineClass);
        }, {
            once: true,
        });
    }
};

export { noOutline };